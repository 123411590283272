$icon-font-path: './fonts/';
$green-color: #5cb85c;
$red-color: #ff433d;
$yellow-color: #f8ff5b;
$active-color: darkred;

/* Vendor styles */
@import "../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

/* Global styles */
body {
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}
.card-icon {
  display: inline-block;
  border-radius: 3px;
  padding: 0.45em 0.7em;
  background-color: #333;
}
.label-card-yes .card-icon {
  background-color: $green-color;
}
.label-card-no .card-icon {
  background-color: $red-color;
}
[data-toggle="popover"] {
  font-size: 1.4em;
}

/* Helpers */
.center-block {
  float: none;
}

/* Forms */
.login-form--forgot-password {
  text-align: right;
  margin-top: 10px;
}
.query-form input {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}
.register-card-form {

  input[type=tel],
  input[type=number] {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }
  .form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px;
  }
}
.glyphicon-phone-large {
  font-size: 26px;
}
.register-card-form--selected-entity-wrap {
  min-height: 78px;
  margin-bottom: 10px;
}
.register-reset-btn {
  margin-left: 10px;
}
#id_membership_trial {
  transform: scale(1.5);
  margin-left: 10px;
}
[for=id_membership_trial] {
  padding-left: 6px;

}

#membership-submit-btn:not(:disabled) {
  background-color: #dff0d8;
  border-color: #3c763d;
}

.query-form {
  margin-bottom: 20px;
}
/* Search result / User */
.search-result {
  font-weight: normal;
  line-height: 2;

  &.can-register-card:hover {
    cursor: pointer;
  }
  .phone-number,
  .labels {
      margin-left: 21px;
  }
  .label {
    font-size: 1em;
  }
  .label-card {
    border: 1px solid #555;
    color: #555;
  }
  .label-card-yes {
    color: $green-color;
  }
  .label-is-volunteer {
    background-color: $active-color;
  }
  [type=radio] {
    position: absolute;
    top: 42px;
    transform: scale(1.5);
    left: 10px;

    &:focus {
      outline: none;
    }
  }
  &.selected {
    background-color: #eee;
    .selected-icon {
      visibility: visible;
    }
  }
  .name {
    margin-left: 21px;
  }
}
.user-actions {
  position: absolute;
  right: 16px;
  top: 40px;
  visibility: hidden;
}
.selected .user-actions {
  visibility: visible;
}


/* Search form */
.selected-icon {
  visibility: hidden;
  float: right;
  bottom: 66px;
  right: 10px;
  position: relative;
  font-size: 2em;
  color: $green-color;
}
#id_query {
  width: 100%;
}
.query-form .form-group {
  width: 80%;
  display: inline-block;

}
/*@media (min-width: $screen-sm-min) {
  .results-wrap {
    overflow-y: auto;
    max-height: 550px;
  }
}*/

/* Footer */
.credits {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  color: #666;
  font-size: 12px;
}
.love {
    color: #faafba;
}
/* Toasts */
.toast-wrap {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, .8);
  color: white;
  min-width: 100px;
  padding: 18px 32px;
  font-size: 18px;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 0.3s ease-in-out;
  z-index: 1;
  pointer-events: none;

  &.visible {
    visibility: visible;
    transform: translateY(0);
    pointer-events: auto;
  }
  .glyphicon {
    padding-right: 10px;
  }
}
.toast-success {
  color: $green-color;
}
.toast-error {
  color: $red-color;
}
.toast-warning {
  color: $yellow-color;
}

/* Header */
@import "header";
